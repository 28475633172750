import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`

  @media ${device.desktopXXL} {
    .gatsby-image-wrapper {
      max-width: 456px;
      float: right;
    }
  }
`
