import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'
import { Section } from './style'

import textPT from '../../assets/data/_controle/textPT.json'
import textEN from '../../assets/data/_controle/textEN.json'

type IyourDataJSON = {
  icon: string;
  text: string;
}

const Controle = (props: {lang: string}) => {
  const pageText = props.lang === 'pt' ? textPT : textEN
  const data = usePageQuery()

  return (
    <Section className='bg-grayscale--100 padding-section'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-5 order-md-2'>
            <Img fluid={data.bannerVoceNoControle.fluid} alt={pageText.altImage} />
          </div>
          <div className='col-12 col-md-6 col-xl-5 mt-4 mt-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3' dangerouslySetInnerHTML={{ __html: pageText.title }} />
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--500'>{pageText.text}</p>
            {pageText.yourData.map((item: IyourDataJSON, index: number) => (
              <div key={index} className='d-flex align-items-center py-2'>
                <div>
                  <OrangeIcon icon={item.icon} color={orange.extra} size='MD' />
                </div>
                <span className='pl-2 fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--500'>{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Controle
