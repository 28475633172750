import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { Wrapper } from '../../privacidade-de-dados/style'

import Hero from '../../privacidade-de-dados/sections/_hero/_index'
import SeuDireitoAPrivacidade from '../../privacidade-de-dados/sections/_seuDireitoAPrivacidade/_index'
import Controle from '../../privacidade-de-dados/sections/_controle/_index'
import ComoMantemosSeguro from '../../privacidade-de-dados/sections/_comoMantemosSeguro/_index'
import EliminamosAsBarreirasPraVoce from '../../privacidade-de-dados/sections/_eliminamosAsBarreirasPraVoce/_index'
import ConhecaNossoEncarregado from '../../privacidade-de-dados/sections/_conhecaNossoEncarregado/_index'
import EstamosSempreMelhorando from '../../privacidade-de-dados/sections/_estamosSempreMelhorando/_index'
import MaisSeguranca from '../../privacidade-de-dados/sections/_maisSeguranca/_index'
import AlgumasDefinicoes from 'src/pages/privacidade-de-dados/sections/_algumas-definicoes/_index'
import ConhecaSeusDireitos from 'src/pages/privacidade-de-dados/sections/_conheca-seus-direitos/_index'
import FAQSection from '../../privacidade-de-dados/sections/_faq/_index'

const PrivacidadeDeDados = () => {
  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <SeuDireitoAPrivacidade lang={lang} />
        <Controle lang={lang} />
        <ComoMantemosSeguro lang={lang} />
        <EliminamosAsBarreirasPraVoce lang={lang} />
        <ConhecaNossoEncarregado lang={lang} />
        <EstamosSempreMelhorando lang={lang} />
        <MaisSeguranca lang={lang} />
        <AlgumasDefinicoes lang={lang} />
        <ConhecaSeusDireitos lang={lang} />
        <FAQSection lang={lang} pageContext={pageContext} inputPlaceholder='Search' />
      </Layout>
    </Wrapper>
  )
}

export default PrivacidadeDeDados
